<template>
  <div class="container-fluid">
    <dx-util-popup
      ref="popupShipmentItemsRef"
      :show-close-button="true"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      :full-screen="true"
      content-template="popup-content"
      :title="popupTitle"
      @shown="onShownShipmentItemsPopup"
      @hidden="onHiddenShipmentItemsPopup"
    >
      <dx-util-position at="center" my="center" />
      <template #popup-content>
        <shipment-items v-if="showShipmentItems" :ship-batch-id="batchId" />
      </template>
    </dx-util-popup>
    <div class="row">
      <div class="col-md-12 px-0">
        <dx-data-grid
          id="uploadBatchGrid"
          ref="uploadBatchGridRef"
          :height="setHeight"
          :data-source="UploadBatchDataSource"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          @option-changed="onOptionChanged"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @toolbar-preparing="onToolbarPreparing($event)"
          @exporting="onExporting"
          @editor-preparing="onEditorPreparing"
        >
          <dx-state-storing
            :enabled="true"
            type="custom"
            :saving-timeout="500"
            :custom-load="loadDataGridState"
            :custom-save="saveDataGridState"
          />
          <template #buttons="{ data }">
            <div>
              <div v-for="item in data" :key="item.id" class="">
                <dx-util-button :text="item.name" :icon="item.icon" class=" w-100 text-left" styling-mode="text" @click="setActionByItem(item)" />
              </div>
            </div>
          </template>
          <dx-sorting mode="single" />
          <dx-remote-operations :paging="true" :sorting="true" :filtering="true" />
          <dx-column-chooser :enabled="true" mode="select" title="Column Chooser" />
          <dx-search-panel
            :highlight-search-text="true"
            :search-visible-columns-only="true"
            :visible="true"
            :width="200"
            placeholder="Search..."
          />
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="120" :fixed="true" fixed-position="left" />
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                type="default"
                icon="bi bi-grid-3x3-gap-fill"
                class="mr-half"
                hint="Item List"
                @click="onClickItemList(data)"
              />
              <dx-util-button :disabled="allowEditing(data)"
                type="warning"
                icon="bi bi-pencil-square"
                class="mr-half"
                hint="Edit"
                @click="onClickEdit(data)"
              />
              <dx-util-button :disabled="allowDeleting(data)"
                type="danger" icon="bi bi-trash"
                hint="Delete"
                @click="onClickDelete(data)"
              />
            </div>
          </template>
          <dx-column data-field="batchName" />
          <dx-column data-field="companyName" :visible="hasPermission" cell-template="accountCell" />
          <dx-column data-field="accountNo" caption="Acc #" :visible="hasPermission" alignment="center" cell-template="accountCell" />
          <dx-column data-field="suiteNo" caption="Suite #" :visible="hasPermission" alignment="center" cell-template="accountCell" />
          <template #accountCell="{ data }">
            <a href="#" @click="onFilterByAccountNoLink(data)">
              <span>{{ data.value }}</span>
            </a>
          </template>
          <dx-column data-field="storeName" />
          <dx-column data-field="status" cell-template="statusCell" />
          <dx-column v-if="showFoundQty" data-field="foundQty" cell-template="foundQtyCell" />
          <template #foundQtyCell="{ data }">
            <span class="d-block badge badge-light-success">
              {{ data.value }}
            </span>
          </template>
          <template #statusCell="{ data }">
            <span class="d-block badge" :class="getFormated(data.value).variant">
              {{ getFormated(data.value).text }}
            </span>
          </template>
          <dx-column data-field="rowCount" caption="Row Count" />
          <dx-column data-field="itemCount" caption="Item Qty" />
          <dx-column data-field="receivedCount" caption="Received Qty" />
          <dx-column data-field="damagedCount" caption="Problem Qty" />
          <dx-column data-field="warehouseName" caption="Warehouse" :visible="isServingTenant" />
          <dx-column data-field="shipDate" data-type="date" />
          <dx-column data-field="createdBy" caption="Uploaded By" data-type="date" :visible="isIndividual" />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <template #filterToolbar>
            <div class="d-flex flex-row align-items-center">
              <div v-if="hasPermission" class="mr-1 w-25">
                <dx-util-number-box
                  v-model="accountNo"
                  :show-clear-button="true"
                  placeholder="Account or Suite No"
                  @key-down="onFilterByAccountNo"
                  @value-changed="onFilterByAccountNo"
                />
              </div>
              <div class="mr-1 w-25">
                <dx-util-select-box
                  v-model="selectedWarehouse"
                  :data-source="warehouses"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="updateWarehouse"
                />
              </div>
              <div class="mr-1 w-25">
                <dx-util-select-box
                  v-model="selectedStore"
                  :data-source="stores"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="updateStore"
                />
              </div>
              <div class="mr-1 w-25">
                <dx-util-select-box
                  v-model="filters.selectedStatus"
                  :data-source="statuses"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="updateStatus"
                />
              </div>
              <div class="mr-1">
                <dx-util-select-box
                  v-model="selectedDateRange"
                  :data-source="dateRangesList"
                  :drop-down-options="{width: '180px'}"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="setDateRanges"
                />
              </div>
              <div v-show="isCustomDateRange" class="mr-1">
                <dx-util-date-box v-model="beginDate" :max="endDate" type="date" @value-changed="searchByFilter" />
              </div>
              <div v-show="isCustomDateRange" class="mr-1">
                <dx-util-date-box v-model="endDate" :min="beginDate" type="date" @value-changed="searchByFilter" />
              </div>
            </div>
          </template>
        </dx-data-grid>
      </div>
    </div>
    <uploaded-batch-edit
      :id="selectedRow.id"
      :batch-name="selectedBatchName"
      :batch-account-no="selectedAccountNo"
      :warehouses="warehouses"
      :component-id="createUploadedBatchComponentId"
      @on-item-saved="onRowChanged"
    />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import predefinedDateRangesEnum, { getPredefinedDateRangesList } from '@/enums/predefinedDateRanges.enum.js'
import useDateRanges from '@/@core/composables/useDateRanges.js'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import store from '@/store'
import settingsService from '@/http/requests/common/settings'
import SettingsTypeEnum from '@/enums/settingsTypeEnum'
import referenceTypeEnum from '@/enums/referenceTypeEnum'
import UploadedBatchFilter from '@/http/models/search-filters/UploadedBatchFilter'
import moment from 'moment'
import {
  isValidAsin,
  isValidUpc,
} from '@/views/apps/ship/validationItem'
import clientShipmentBatchStatusEnum, { getClientShipmentBatchStatusEnumList } from '@/enums/client-shipment-batch-status.enum'
import { Notify } from '@robustshell/utils'
import { v4 as uuidv4 } from 'uuid'
import shipService from '@/http/requests/ship/shipService'
import commonEntityService from '@/http/requests/common/commonEntityService'
import useCurrentUser from '@/libs/app/current-user'
import { DataGridNamesEnum } from '@/enums'
import ShipmentItems from '@/views/apps/ship/shipment-items/ShipmentItems.vue'
import useConfirmation from '@/libs/app/confirmation'
import { UploadBatchDataSource } from './uploadBatchStore'
import UploadedBatchEdit from './UploadedBatchEdit.vue'

export default {
  components: {
    'uploaded-batch-edit': UploadedBatchEdit,
    'shipment-items': ShipmentItems,
  },
  mixins: [GridBase],
  props: {
    qAccountNo: {
      type: Number,
      default: null,
    },
    qWarehouseId: {
      type: Number,
      default: null,
    },
    qStatus: {
      type: String,
      default: '',
    },
    qStoreId: {
      type: Number,
      default: null,
    },
  },
  data() {
    const today = new Date()
    const last30Days = new Date()
    last30Days.setDate(today.getDate() - 30)
    last30Days.setHours(0, 0, 0, 0)
    const plusOneDay = new Date(today)
    plusOneDay.setDate(today.getDate() + 1)
    plusOneDay.setHours(0, 0, 0, 0)
    return {
      filters: {
        query: '',
        selectedStatus: 'all',
      },
      isSearchAction: false,
      selectedDateRange: predefinedDateRangesEnum.LAST30DAYS.value,
      dateRangesList: getPredefinedDateRangesList(),
      beginDate: last30Days,
      endDate: plusOneDay,
      selectedRow: {
        id: 0,
      },
      UploadBatchDataSource,
      statuses: [],
      isIndividual: false,
      createUploadedBatchComponentId: null,
      selectedBatchName: '',
      selectedAccountNo: '',
      accountNo: this.qAccountNo || null,
      warehouses: [],
      selectedWarehouse: this.qWarehouseId || 0,
      stores: [],
      selectedStore: this.qStoreId || 0,
      dataSource: [],
      batchId: '',
      batchName: '',
      popupTitle: '',
      showShipmentItems: false,
      currentGridStateId: '',
      showFoundQty: false,
    }
  },
  setup() {
    const { pshConfirm } = useConfirmation()
    const { setDateRanges, startDate, isCustomDateRange } = useDateRanges()
    const {
      userId,
      userCompanyId,
      isServingTenant,
      isIndividualTenant,
      userCompanyType,
    } = useCurrentUser()
    return {
      pshConfirm,
      setDateRanges,
      startDate,
      isCustomDateRange,
      userId,
      userCompanyId,
      isServingTenant,
      userCompanyType,
      isIndividualTenant,
    }
  },
  computed: {
    dataGrid() {
      const dataGridIns = this.$refs.uploadBatchGridRef.instance
      return dataGridIns
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    hasUploadPermission() {
      return this.$can('read', 'resource_ship_file_upload')
    },
    isIndividualOrCustomer() {
      return this.isIndividualTenant || this.userCompanyType === 'customer'
    },
    currentRouteQuery() {
      return { ...this.$route.query }
    },
  },
  watch: {
    selectedDateRange() {
      const plusOneDay = new Date()
      plusOneDay.setDate(plusOneDay.getDate() + 1)
      this.beginDate = this.startDate || this.beginDate
    },
  },
  async created() {
    await this.getWarehouses()
    await this.getStores()
    this.statuses = [...getClientShipmentBatchStatusEnumList()]
  },
  mounted() {
    this.accountNo = this.qAccountNo
    this.selectedWarehouse = this.qWarehouseId
    this.filters.selectedStatus = this.qStatus
    this.loadData(true)
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.isIndividual = userData.companyType === 'warehouse'
  },
  methods: {
    getWarehouses() {
      shipService.fetchWarehouses().then(result => {
        const data = result.data
        this.setWarehouse(data)
      })
    },
    setWarehouse(data) {
      this.warehouses.splice(0, this.warehouses.length)
      this.warehouses.push({ value: 0, text: 'Warehouse (All)' })
      this.selectedWarehouse = 0
      data.forEach(element => {
        if (element.name.toLowerCase() !== 'common') {
          this.warehouses.push({ value: element.id, text: element.name })
        }
      })
    },
    getStoresByParentCompanyAccountNo(accountNo) {
      commonEntityService
        .fetchStoresByParentCompanyAccountNo(accountNo)
        .then(result => {
          const data = result.data
          this.setStores(data)
        })
    },
    getStoresForCurrentCompany() {
      commonEntityService.fetchStoresForCurrentCompany().then(result => {
        const data = result.data
        this.setStores(data)
      })
    },
    getStores() {
      if (this.accountNo) {
        this.getStoresByParentCompanyAccountNo(this.accountNo)
      } else {
        this.getStoresForCurrentCompany()
      }
    },
    setStores(data) {
      this.stores.splice(0, this.stores.length)
      this.stores.push({
        value: 0,
        text: 'Store (All)',
      })
      data.forEach(element => {
        this.stores.push({
          value: element.id,
          text: element.name,
          companyId: element.parentCompany,
        })
      })
      if (this.stores.length > 0) {
        const qStoreId = this.$route.query.qStoreId
        if (qStoreId) {
          this.selectedStore = parseInt(qStoreId, 10)
          return
        }
        this.selectedStore = this.stores[0].value
      }
    },
    updateWarehouse() {
      this.$router.push({ query: { ...this.currentRouteQuery, qWarehouseId: this.selectedWarehouse } }).catch(() => {})
      this.searchByFilter()
    },
    updateStatus() {
      this.$router.push({ query: { ...this.currentRouteQuery, qStatus: this.filters.selectedStatus } }).catch(() => {})
      this.searchByFilter()
    },
    updateStore() {
      this.$router.push({ query: { ...this.currentRouteQuery, qStoreId: this.selectedStore } }).catch(() => {})
      this.searchByFilter()
    },
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        UploadBatchDataSource.pageIndex(0)
        UploadBatchDataSource.reload()
        this.dataSource = UploadBatchDataSource
      }
    },
    getFormated(status) {
      if (status === clientShipmentBatchStatusEnum.OPEN.value) return { variant: 'badge-info', text: clientShipmentBatchStatusEnum.OPEN.text }
      if (status === clientShipmentBatchStatusEnum.COMPLETE.value) return { variant: 'badge-success', text: clientShipmentBatchStatusEnum.COMPLETE.text }
      if (status === clientShipmentBatchStatusEnum.NEEDS_ACTION.value) return { variant: 'badge-danger', text: clientShipmentBatchStatusEnum.NEEDS_ACTION.text }
      if (status === clientShipmentBatchStatusEnum.DRAFT.value) return { variant: 'badge-warning', text: clientShipmentBatchStatusEnum.DRAFT.text }
      if (status === clientShipmentBatchStatusEnum.RETURN_REMOVAL.value) return { variant: 'badge-warning', text: clientShipmentBatchStatusEnum.RETURN_REMOVAL.text }
      if (status === clientShipmentBatchStatusEnum.REMOVAL.value) return { variant: 'badge-warning', text: clientShipmentBatchStatusEnum.REMOVAL.text }
      return { variant: 'primary', text: 'Other' }
    },
    // #region Datagrid initialial configurations
    allowDeleting(e) {
      return (e.data.status === clientShipmentBatchStatusEnum.COMPLETE.value
      || e.data.status === clientShipmentBatchStatusEnum.NEEDS_ACTION.value
      || e.data.status === clientShipmentBatchStatusEnum.RETURN_REMOVAL.value
      || e.data.status === clientShipmentBatchStatusEnum.REMOVAL.value
      || (e.data.status === clientShipmentBatchStatusEnum.OPEN.value && e.data.damagedCount > 0)
      || (e.data.status === clientShipmentBatchStatusEnum.OPEN.value && e.data.receivedCount > 0))
    },
    allowEditing(e) {
      return e.data.status === clientShipmentBatchStatusEnum.NEEDS_ACTION.value
      || e.data.status === clientShipmentBatchStatusEnum.RETURN_REMOVAL.value
      || e.data.status === clientShipmentBatchStatusEnum.REMOVAL.value
    },
    async setActionByItem(item) {
      switch (item.id) {
        case 'resetDatagridState':
          this.resetGridState()
          break
        default:
          break
      }
    },
    async resetGridState() {
      if (this.currentGridStateId === null) {
        Notify.warning('This datagrid does not have any settings to reset.')
        return
      }
      await settingsService.delete(this.currentGridStateId)
      window.location.reload()
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          visible: this.isIndividualOrCustomer,
          text: 'Pending Items',
          type: 'warning',
          stylingMode: 'contained',
          onClick: () => {
            this.$router.push({ name: 'pending-inbound-items' })
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          visible: this.hasUploadPermission,
          text: 'Upload File',
          icon: 'upload',
          type: 'default',
          stylingMode: 'contained',
          onClick: () => {
            this.$router.push({ name: 'file-upload' })
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Create Inbound',
          icon: 'add',
          type: 'success',
          onClick: () => {
            this.selectedRow.id = 0
            this.createUploadedBatchComponentId = uuidv4()
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.loadData(false)
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          icon: 'menu',
          showArrowIcon: false,
          items: [
            {
              id: 'resetDatagridState',
              name: 'Reset Datagrid Settings',
              icon: 'icon bi-x-square',
            },
          ],
          width: 36,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Set Preferences',
          dropDownOptions: { width: 'auto' },
          dropDownContentTemplate: 'buttons',
        },
        location: 'after',
      })
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        this.isSearchAction = true
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.filters.query = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filters.query = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.filters.query = ''
            this.searchByFilter()
          }
        }
      }
    },
    // #endregion

    // #region Datagrid data load
    loadData(initial) {
      UploadedBatchFilter.setDefaultFilters()
      this.selectedDateRange = predefinedDateRangesEnum.LAST30DAYS.value
      const today = new Date()
      const last30Days = new Date()
      last30Days.setDate(today.getDate() - 30)
      last30Days.setHours(0, 0, 0, 0)
      this.beginDate = last30Days
      UploadedBatchFilter.beginDate = this.beginDate
      UploadedBatchFilter.endDate = this.endDate
      if (initial) {
        UploadedBatchFilter.accountNo = this.accountNo
        const filters = UploadedBatchFilter.getFilters()
        UploadBatchDataSource.searchValue(filters)
        this.dataSource = UploadBatchDataSource
      } else {
        if (this.hasPermission) this.accountNo = null
        this.dataGrid.clearFilter()
        UploadedBatchFilter.accountNo = this.accountNo
        const filters = UploadedBatchFilter.getFilters()
        UploadBatchDataSource.searchValue(filters)
        UploadBatchDataSource.reload()
        this.dataSource = UploadBatchDataSource
        this.selectedWarehouse = UploadedBatchFilter.warehouseId
        this.filters.query = UploadedBatchFilter.q
        this.filters.selectedStatus = UploadedBatchFilter.status
      }
    },
    async searchByFilter() {
      this.selectedStatus = typeof this.filters.selectedStatus === 'undefined' ? clientShipmentBatchStatusEnum.All.value : this.filters.selectedStatus
      UploadedBatchFilter.accountNo = this.accountNo
      UploadedBatchFilter.storeId = this.selectedStore
      UploadedBatchFilter.warehouseId = this.selectedWarehouse
      UploadedBatchFilter.status = this.filters.selectedStatus
      UploadedBatchFilter.beginDate = this.beginDate
      UploadedBatchFilter.endDate = this.endDate
      UploadedBatchFilter.productCode = this.filters.query !== '' && (isValidUpc(this.filters.query) || isValidAsin(this.filters.query))
      UploadedBatchFilter.q = this.filters.query

      const filters = UploadedBatchFilter.getFilters()
      this.showFoundQty = filters.productCode
      UploadBatchDataSource.searchValue(filters)
      UploadBatchDataSource.reload()
      this.dataSource = UploadBatchDataSource
    },
    onFilterByAccountNoLink(row) {
      this.accountNo = row.data.accountNo
      this.$router.replace({ query: { ...this.currentRouteQuery, qAccountNo: this.accountNo } }).catch(() => {})
      this.getStores()
      this.searchByFilter()
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = parseInt(e.event.target.value, 10)
          this.getStores()
          this.searchByFilter()
          this.$router.replace({ query: { ...this.currentRouteQuery, qAccountNo: this.accountNo, qStoreId: 0 } }).catch(() => {})
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = null
        this.$router.replace({ query: { ...this.currentRouteQuery, qAccountNo: null, qStoreId: 0 } }).catch(() => {})
        this.getStores()
        this.searchByFilter()
      }
    },
    async onRowChanged() {
      await this.searchByFilter()
    },
    async loadDataGridState(e) {
      const dataGridKey = DataGridNamesEnum.INBOUND_SHIPMENT_BATCHES_GRID.key
      const result = await settingsService.getByReference(referenceTypeEnum.USER.value, this.userId, dataGridKey)
      this.currentGridStateId = result.data.id
      this.dataGridState = {}
      if (result.data.value) {
        const state = JSON.parse(result.data.value)
        state.searchText = ''
        state.filterValue = null
        state.pageIndex = 0
        const columns = []
        state.columns.forEach(column => {
          const newColumn = {
            visibleIndex: column.visibleIndex,
            dataField: column.dataField,
            name: column.name,
            dataType: column.dataType,
            width: column.width,
            visible: column.visible,
          }
          columns.push(newColumn)
        })
        state.columns = columns
        this.dataGridState = state
      }
      return this.dataGridState
    },
    async saveDataGridState(e) {
      if (e === undefined || e === null) {
        return
      }
      if (JSON.stringify(e) === JSON.stringify(this.dataGridState)) {
        return
      }
      if (e.searchText !== '' || this.isSearchAction) {
        this.isSearchAction = false
        return
      }
      const state = e
      state.searchText = ''
      state.filterValue = null
      state.pageIndex = 0
      const columns = []
      state.columns.forEach(column => {
        const newColumn = {
          visibleIndex: column.visibleIndex,
          dataField: column.dataField,
          name: column.name,
          dataType: column.dataType,
          width: column.width,
          visible: column.visible,
        }
        columns.push(newColumn)
      })
      state.columns = columns
      const dataGridKey = DataGridNamesEnum.INBOUND_SHIPMENT_BATCHES_GRID.key
      const preferenceData = {
        companyId: this.userCompanyId,
        referenceId: this.userId,
        referenceType: referenceTypeEnum.USER.value,
        settingType: SettingsTypeEnum.DATAGRID_STATES.value,
        settingKey: dataGridKey,
        description: `State of the ${dataGridKey} for UserId: ${this.userId}`,
        settingName: dataGridKey,
        value: JSON.stringify(state),
      }
      const result = await settingsService.createOrUpdate(preferenceData)
      this.currentGridStateId = result.data.body.id
    },
    // #endregion
    onClickItemList(e) {
      const { data } = e.row
      const ts = moment(data.shipDate).valueOf()
      store.dispatch('appLocal/setUploadedShipmentItemsParams',
        {
          storeId: data.storeId,
          companyId: data.companyId,
          batchId: data.id,
          batchName: data.batchName,
          shipDate: ts,
        })
      if (data.status === clientShipmentBatchStatusEnum.DRAFT.value) {
        this.$router.push({ name: 'batch-workspace', params: { batchId: data.id }, query: { ...this.currentRouteQuery } })
      } else {
        this.$router.push({ name: 'route-shipment-items', params: { status: data.status } })
      }
    },
    onClickEdit(e) {
      if (e.row.data.status === clientShipmentBatchStatusEnum.NEEDS_ACTION.value
          || e.row.data.status === clientShipmentBatchStatusEnum.RETURN_REMOVAL.value
          || e.row.data.status === clientShipmentBatchStatusEnum.REMOVAL.value) {
        Notify.warning('This row cannot be updated.')
        return
      }

      this.selectedRow = { ...e.data }
      this.selectedBatchName = e.data.batchName

      this.selectedAccountNo = (typeof e.data.accountNo === 'number')
        ? e.data.accountNo.toString()
        : e.data.accountNo

      this.createUploadedBatchComponentId = uuidv4()
      this.$root.$emit('bv::toggle::collapse', 'uploaded-batch-edit-sidebar')
    },
    async onClickDelete(e) {
      const confirm = await this.pshConfirm(
        'Delete Item Confirmation',
        `<p>Are you sure you want to delete this batch?</p>
         <h4 class="text-danger mt-half">This action cannot be undone.</h4>`,
        'exclamation-circle-fill',
        'Delete',
        'Cancel',
        'warning',
      )
      if (confirm) {
        try {
          await shipService.deleteBatchById(e.row.key)
          await this.onRowChanged()
        } catch (error) {
          Notify.error(error)
        }
      }
    },
    onShownShipmentItemsPopup() {
      this.showShipmentItems = true
      this.popupTitle = `${this.batchName}: Shipment Batch Items`
    },
    onBatchNameLinkClick(e) {
      this.batchId = e.data.id
      this.batchName = e.value
      const popup = this.$refs.popupShipmentItemsRef.instance
      popup.show()
    },
    onHiddenShipmentItemsPopup() {
      this.showShipmentItems = false
    },
    // #endregion
  },
}
</script>

<style lang="scss">
</style>
