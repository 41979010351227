<template>
  <dx-util-popup
    ref="batchUploadRef"
    :show-close-button="true"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-title="true"
    :full-screen="false"
    width="35vw"
    height="auto"
    :title="batchUploadPopupTitle"
    @shown="onShownBatchUploadPopup"
    @hidden="onHiddenBatchUploadPopup"
  >
    <template>
      <div>
        <dx-util-validation-group ref="batchUploadFormRef">
          <div class="my-1 col">
            <dx-util-text-box v-model="formData.batchName" label="Batch Name">
              <dx-util-validator>
                <dx-util-required-rule message="Batch name is required" />
              </dx-util-validator>
            </dx-util-text-box>
          </div>
          <div class="my-1 col">
            <dx-util-text-box
              v-if="hasPermission"
              v-model="accountNo"
              :disabled="editMode"
              :visible="isCompanyTypeWarehouse"
              label="Account or Suite No"
              class="mr-1 px-0 col"
              @key-down="getCompanyByAccount"
            >
              <dx-text-box-button
                location="after"
                name="accountTextBoxButton"
                :options="accountTextBoxButtonOptions"
              />
          </dx-util-text-box>
          </div>
          <div class="my-1 col">
            <dx-util-select-box
              v-model="selectedStore"
              :disabled="editMode"
              label="Store"
              :data-source="stores"
              display-expr="text"
              value-expr="value"
            />
          </div>
          <div class="my-1 col">
            <dx-util-select-box
              v-model="selectedWarehouse"
              :disabled="editMode"
              label="Warehouse"
              :data-source="warehousesInSideBar"
              display-expr="text"
              value-expr="value"
            />
          </div>
          <div class="my-1 col">
            <dx-util-text-area
              v-model="formData.notes"
              label="Notes"
              :max-length="250"
            />
          </div>
          <div class="d-flex p-1 justify-content-end">
            <dx-util-button type="danger" text="Cancel" class="mr-1" style="min-width:100px" @click="onClose" />
            <dx-util-button type="success" text="Save" class="" style="min-width:100px" @click="onSubmit" />
          </div>
          </dx-util-validation-group>
      </div>
    </template>
  </dx-util-popup>
</template>

<script>
// eslint-disable no-bitwise
import { Notify } from '@robustshell/utils/index'
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import shipService from '@/http/requests/ship/shipService'
import companyService from '@/http/requests/company/companyService'
import commonEntityService from '@/http/requests/common/commonEntityService'
import useCurrentUser from '@/libs/app/current-user'
import clientShipmentItemStatusEnum from '@/enums/client-shipment-batch-status.enum'

export default {
  components: {
    DxTextBoxButton,
  },
  props: {
    warehouses: {
      type: Array,
      required: true,
    },
    componentId: {
      type: String,
      default: '',
    },
    id: {
      type: Number,
      default: null,
    },
    batchName: {
      type: String,
      default: '',
    },
    batchAccountNo: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    const { isCompanyTypeWarehouse } = useCurrentUser()
    return {
      isCompanyTypeWarehouse,
    }
  },
  data() {
    return {
      formData: {
        batchName: null,
        notes: '',
      },
      selectedStore: 0,
      selectedWarehouse: 0,
      editMode: false,
      stores: [],
      batchUploadPopupTitle: '',
      accountNo: '',
      companyName: '',
      companyId: 0,
    }
  },
  computed: {
    warehousesInSideBar() {
      return this.warehouses.map(el => {
        if (el.value === 0) {
          // eslint-disable-next-line no-param-reassign
          el.text = 'Warehouse (All)'
        }
        return el
      })
    },
    batchUploadPopupIns() {
      return this.$refs.batchUploadRef.instance
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    accountTextBoxButtonOptions() {
      return {
        text: 'Get Account',
        type: 'default',
        onClick: () => {
          const accountNo = this.accountNo.replace(/\D/g, '')
          this.getCompanyByAccountNo(accountNo)
        },
      }
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler(current) {
        if (current) {
          this.createBatchUploadPopup()
        }
      },
    },
  },
  methods: {
    getCompanyByAccount(e) {
      if (e.event.keyCode !== 13) return
      const accountNo = e.event.target.value
      this.accountNo = accountNo.replace(/\D/g, '')
      this.getCompanyByAccountNo(this.accountNo)
    },
    getCompanyByAccountNo(accountNo) {
      if (!accountNo || accountNo === null || accountNo === 0 || accountNo === '' || accountNo === undefined) {
        Notify.error('Please enter a valid account no')
        return
      }
      companyService.fetchByAccountNo(accountNo).then(result => {
        this.companyId = result.data.id
        this.companyName = result.data.name
        this.accountNo = accountNo
        this.getStores(result.data.id)
      })
    },
    createBatchUploadPopup() {
      this.batchUploadPopupIns.show()
    },
    onShownBatchUploadPopup() {
      this.onShown()
      this.batchUploadPopupTitle = !this.editMode ? 'Create New' : `Edit ${this.id} - ${this.batchName}`
    },
    onHiddenBatchUploadPopup() {
      this.onClose()
    },
    onClose() {
      this.batchUploadPopupIns.hide()
      this.$emit('on-item-saved')
      this.resetForm()
    },
    async onSubmit(e) {
      const validationResult = e.validationGroup.validate()
      if (this.selectedWarehouse === null || this.selectedWarehouse === 0 || this.selectedWarehouse === '') {
        Notify.warning('Please select a warehouse')
        return
      }
      if (this.selectedStore === null || this.selectedStore === 0 || this.selectedStore === '') {
        Notify.warning('Please select a store')
        return
      }
      if (validationResult.isValid) {
        this.formData.storeId = this.selectedStore
        this.formData.warehouse = this.selectedWarehouse
        this.formData.companyId = this.companyId
        if (this.editMode) {
          await shipService.updateBatch(this.formData)
        } else {
          const data = { ...this.formData }
          data.status = clientShipmentItemStatusEnum.DRAFT.value
          await shipService.createBatch(data)
        }
        this.$emit('on-item-saved')
        this.onClose()
      } else {
        Notify.warning('Please check the fields.')
      }
    },
    resetForm() {
      this.$refs.batchUploadFormRef.resetValues()
      this.formData.notes = ''
      this.selectedStore = 0
      this.selectedWarehouse = 0
      this.editMode = false
      this.stores = []
      this.accountNo = ''
      this.companyName = 0
    },
    onShown() {
      if (this.warehouses.length === 1) {
        this.selectedWarehouse = this.warehouses[0].value
      }
      this.editMode = this.id !== 0
      this.formData = { notes: '' }

      if (this.editMode) {
        shipService.fetchBatchById(this.id).then(result => {
          const { data } = result
          if (!('notes' in data)) {
            data.notes = ''
          }
          if (data.notes === null || data.notes === 'undefined') {
            data.notes = ''
          }
          this.formData = data
          this.accountNo = this.batchAccountNo
          this.selectedStore = this.formData.storeId
          this.selectedWarehouse = this.formData.warehouse
          this.companyId = this.formData.companyId
        }).then(() => {
          this.getStores(this.companyId)
        })
      }

      this.getAddStores()
    },
    getAddStores() {
      this.stores.splice(0, this.stores.length)
      commonEntityService.fetchStoresForCurrentCompany().then(result => {
        const data = result.data
        this.stores.push({ value: 0, text: 'Select a store' })
        data.forEach(element => {
          this.stores.push({ value: element.id, text: element.name })
        })
        this.selectedStore = 0
      })
    },
    getStores(companyId) {
      if (companyId === '' || companyId === null || typeof companyId === 'undefined' || companyId === 0) {
        return
      }
      this.stores.splice(0, this.stores.length)
      commonEntityService.fetchStoresByParentCompany(companyId).then(result => {
        const data = result.data
        this.stores.push({ value: 0, text: 'Store (All)' })
        data.forEach(element => {
          this.stores.push({ value: element.id, text: element.name })
        })
        this.selectedStore = 0
        if (this.editMode) {
          this.selectedStore = this.formData.storeId
        }
      })
    },
  },
}
</script>
